import React, { Component } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { connect, type ConnectedProps } from "react-redux";
import { NavLink } from "react-router-dom";
import { getFormValues } from "redux-form";

import { getLastCorrection } from "~/common/patient";
import { canOrderTestPlastic } from "~/common/user";
import { Button } from "~/components/ui/button";
import { Portlet, PortletTerm } from "~/components/ui/portlet";

import { eraseStateProp } from "../../../actions/dashboard";
import {
  EXTRA_SERVICE_OPTION_TRANSLATION_MAP,
  GENDER_MAP,
  Payer,
  PAYERS_TRANSLATION_MAP,
  PaymentMethod,
  RxType
} from "../../../common/constants";
import {
  CO_TAG_FULL_3_STEPS_CBCT,
  CO_TAG_TEEN_3_STEPS_CBCT,
  COURSE_VERSION_V2,
  COURSE_VERSION_V3,
  hasDeepCBCT,
  isChildrenShortCourse,
  isRetainersCourse
} from "../../../common/courses";
import remoteLog from "../../../common/logging";
import { translateRxOption } from "../../../common/prescription";
import { convertToDentalNotation, deployedRussia, deployedUSA } from "../../../common/utils";
import { API_PATIENT_IMAGE } from "../../../config";
import type { TCorrection, TPatient, TPatientList } from "../../../reducers/dashboard";
import type { RootState } from "../../../store";
import { SBCreditComponent } from "../../common/common";
import { FormatDate } from "../../common/FormatDate";
import { newLineToLineBreakTag } from "../../common/formatText/formatText";
import { PersonName } from "../../common/PersonName";
import ProbablyFormattedMessage from "../../probably_formatted_message";
import PatientFiles, {
  FILE_TYPE_3SHAPE,
  FILE_TYPE_DICOM,
  FILE_TYPE_MEDIT,
  FILE_TYPE_PREXION
} from "../patient_files";
import {
  PatientElasticsOptionsTeethItemsReadOnly,
  PatientTeethItemsReadOnly
} from "../patient_teeth_items";
import PatientCorrectionPhoto from "./patient_correction_photo";


const mapStateToProps = (state: RootState) => {
    return {
        lang: state.intl,
        user: state.user,
        services: state.services,
        instructions: state.instructions,
        formValues: getFormValues('correction')(state),
    }
}


const mapDispatchToProps = (dispatch) => ({ erasePatient: () => dispatch(eraseStateProp('patient')) })


class OptionalMedia extends Component {


    componentDidCatch(e: Error) {
        remoteLog(e, 'patient_card');
    }

    render() {
        const pfx = this.props.prefix;
        const media = this.props.media;
        let fileName = media.linkto.split("/");
        fileName = fileName[fileName.length - 1];

        if (media.thumbnail.match('THUMBNAIL')) {
            return (
                <tr className="template-download fade in">
                    <td style={{ width: '40%' }}>
                        <div>
                            <a
                                href={pfx + media.linkto}
                                title={media.linkto}
                                download={pfx + media.linkto}
                                data-gallery
                                data-matomo-mask
                                data-hj-suppress
                            >
                                <img
                                    src={'/img/upload_placeholders/' + media.thumbnail + '.svg' + `?${Number(new Date())}`}
                                    style={{ width: '60%' }}
                                />
                            </a>
                        </div>
                    </td>
                    <td>
                        <p className="name">
                            <a
                                href={pfx + encodeURIComponent(media.linkto)}
                                title={fileName}
                                download={pfx + encodeURIComponent(media.linkto)}
                                data-gallery
                                data-matomo-mask
                                data-hj-suppress
                            >
                                {fileName}
                            </a>
                        </p>
                    </td>
                    <td>
                        <span className="size">{media.media_size} kB</span>
                    </td>
                </tr>
            );
        } else {
            return (
                <tr className="template-download fade in">
                    <td>
                        <span className="preview">
                            <a
                                href={pfx + media.linkto}
                                title={fileName}
                                download={pfx + media.linkto}
                                data-gallery
                                data-matomo-mask
                                data-hj-suppress
                            >
                                <img src={pfx + media.thumbnail + `?${Number(new Date())}`} />
                            </a>
                        </span>
                    </td>
                    <td>
                        <p className="name">
                            <a
                                href={pfx + media.linkto}
                                title={fileName}
                                download={pfx + media.linkto}
                                data-gallery
                                data-matomo-mask
                                data-hj-suppress
                            >
                                {fileName}
                            </a>
                        </p>
                    </td>
                    <td>
                        <span className="size">{media.media_size} kB</span>
                    </td>
                </tr>
            );
        }
    }
}


const RequiredPhoto = ({ prefix, view }) => {
    if (view) {
        return (
            <a href={prefix + view.linkto} target="_blank">
                <img src={prefix + view.thumbnail + `?${Number(new Date())}`} width="100%" alt="" />
            </a>
        );
    }
    return null;
}

type PatientCardProps = PropsFromRedux & {
  patient: TPatient;
};

type PatientCardState = {
  selected: number;
  rxTypeId: TPatient["rx_type_id"];
  prescription: TCorrection["prescription"] | null;
  links: TCorrection["links"] | null;
};

class PatientCard extends Component<PatientCardProps, PatientCardState> {
    constructor(props: PatientCardProps) {
        super(props)
        this.state = {
            selected: this.props.patient.course ? this.props.patient.course.correction.length - 1 : 0,
            rxTypeId: this.props.patient.course && this.props.patient.course.correction[this.props.patient.course.correction.length - 1].prescription !== null
                ? this.props.patient.course.correction[this.props.patient.course.correction.length - 1].prescription.rx_type_id
                : this.props.patient.rx_type_id,
            prescription: this.props.patient
                ? this.props.patient.course.correction[this.props.patient.course.correction.length - 1].prescription
                : null,
            links: this.props.patient
                ? this.props.patient.course.correction[this.props.patient.course.correction.length - 1].links
                : null,
        }
    }

    componentWillUnmount() {
        this.props.erasePatient();
    }

    renderCourseName() {
        const { patient } = this.props;
        const locale = this.props.lang.locale;

        if (deployedRussia()) {
            if (patient && locale === 'ru') {
              if (patient.course_version == COURSE_VERSION_V2) {
                return <p><ProbablyFormattedMessage id={`${patient.course.course}_V2`} /></p>;
              } else if (patient.course_version == COURSE_VERSION_V3) {
                return <p><ProbablyFormattedMessage id={`${patient.course.course}_V3`} /></p>;
              }
            }
            return <p id="course-value"><ProbablyFormattedMessage id={patient.course.course} /></p>;
        }
        return <p id="course-value"><ProbablyFormattedMessage id={`${patient.course.course}_USA`} /></p>;
    }

    renderExpansion(arch) {
        const instructions = this.state.prescription ? this.state.prescription : null;
        if (instructions && instructions[`${arch}_arch_action`] === 1) {
            if (instructions[`${arch}_arch_expansion_bodily`] && instructions[`${arch}_arch_expansion_torque`]) {
                return (
                    <p id={`${arch}-arch-action`}>
                        <FormattedMessage id={translateRxOption([`${arch}_arch_action`], instructions[`${arch}_arch_action`])} />
                        &nbsp;(<FormattedMessage id="TA_U_EXPAND_TYPE_TORQUE" />,
                        &nbsp;<FormattedMessage id="TA_U_EXPAND_TYPE_BODILY" />)
                    </p>
                );
            } else if (instructions[`${arch}_arch_expansion_bodily`]) {
                return (
                    <p id={`${arch}-arch-action`}>
                        <FormattedMessage id={translateRxOption([`${arch}_arch_action`], instructions[`${arch}_arch_action`])} />
                        &nbsp;(<FormattedMessage id="TA_U_EXPAND_TYPE_BODILY" />)
                    </p>
                );
            } else if (instructions[`${arch}_arch_expansion_torque`]) {
                return (
                    <p id={`${arch}-arch-action`}>
                        <FormattedMessage id={translateRxOption([`${arch}_arch_action`], instructions[`${arch}_arch_action`])} />
                        &nbsp;(<FormattedMessage id="TA_U_EXPAND_TYPE_TORQUE" />)
                    </p>
                );
            }
        }
        return <p><FormattedMessage id={translateRxOption([`${arch}_arch_action`], instructions[`${arch}_arch_action`])} /></p>;
    }

    renderExtraServices() {
        const { services } = this.props;
        const { patient: { course } } = this.props;
        const rows = [];
        let deep_cbct_added = false;

        if (services && Array.isArray(services) && services.length > 0) {
            {
                services.forEach((service) => {
                    if (service.tag === 'CBCT_ANALYSIS') {
                        deep_cbct_added = true;
                    }
                    rows.push(
                        <div key={service['id']}>
                            <label className="control-label" key={service['id']}>
                                <FormattedMessage id={service.tag} />
                                {service.option !== null && service.option.material !== null
                                    ? (
                                        <span>
                                            &nbsp;(
                                            <FormattedMessage
                                                id={EXTRA_SERVICE_OPTION_TRANSLATION_MAP[service.option.material]}
                                            /> )
                                        </span>
                                    )
                                    : null
                                }
                            </label>&nbsp;
                        </div>
                    )
                })
            }
        }
        if (
            (course.course_option_tag === CO_TAG_FULL_3_STEPS_CBCT || course.course_option_tag === CO_TAG_TEEN_3_STEPS_CBCT)
            && deep_cbct_added === false
        ) {
            rows.push(
                <div key='cbct_for_full'>
                    <label className="control-label"><FormattedMessage id='CBCT_ANALYSIS' /></label>
                </div>
            )
        }
        return rows;
    }

    handleChange(index: number) {
        const rxTypeId = this.props.patient.course.correction[index].prescription !== null
            ? this.props.patient.course.correction[index].prescription.rx_type_id
            : this.props.patient.rx_type_id;
        this.setState({ selected: index, rxTypeId: rxTypeId });
        this.setState({
            prescription: this.props.patient.course.correction[index].prescription,
            links: this.props.patient.course.correction[index].links,
            dont_move: 'dont_move' + Math.random(),
            avoid_locks: 'avoid_locks' + Math.random(),
            extractions: 'extractions' + Math.random(),
            hooks: 'hooks' + Math.random(),
            buttons: 'buttons' + Math.random(),
            elastics: 'elastics' + Math.random(),
            teething: 'teething' + Math.random()
        });
    }

    render() {
        const { user, patient: p } = this.props;
        const locale = this.props.lang.locale;

        const dentalNotation  = user.preferences?.dental_notation;
        const pmi = p.media ? p.media.required_images : null;
        const pmo = p.media.optional_media;
        const pmp = p.media.plan_media;
        const pfx = API_PATIENT_IMAGE(p.patient_id, '');
        const { services } = this.props;
        const old_instructions = this.props.instructions;
        const instructions = this.state.prescription ? this.state.prescription : null;
        const elastics = instructions && instructions.elastics_options
            ? Object.keys(instructions.elastics_options).map(i => parseInt(i))
            : null
        const close_all_gaps_spaces = instructions && instructions.close_all_gaps_spaces;
        const links = this.state.links ? this.state.links : [];
        const comment = instructions ? instructions.comment : "";
        const { rxTypeId } = this.state;
        const correct_proclination_lower = instructions ? instructions.correct_proclination_lower : null;
        const correct_proclination_upper = instructions ? instructions.correct_proclination_upper : null;
        const correct_retroclination_lower = instructions ? instructions.correct_retroclination_lower : null;
        const correct_retroclination_upper = instructions ? instructions.correct_retroclination_upper : null;
        const hasCorrection = this.props.patient.course.correction.length > 1;
        const lastCorrection = getLastCorrection(this.props.patient);
        const isChildrenShort = isChildrenShortCourse(p.course.course_id);

        return (
            <Portlet id="patient-card">
                <div className="portlet-title">
                    <div className="caption">
                        <i className="icon-book-open font-green" />
                        <span className="caption-subject font-green bold uppercase" id="patient-block-label">
                            <FormattedMessage id="pat.view.header" />
                        </span>
                    </div>
                    <div className="form-actions">
                        <Button variant="grey" asChild>
                            <NavLink to={"/pages/edit_patient/" + this.props.patient.patient_id} style={{ float: "right" }}>
                                <FormattedMessage id="BTN_EDIT" />
                            </NavLink>
                        </Button>
                    </div>
                </div>

                <div className="portlet-body" id="patient-block-body">
                    <h3 className="block" id="patient-info-label">
                        <FormattedMessage id="BLOCKHEAD_PAT_INFO" />
                    </h3>

                    <PortletTerm id="patient-name-label">
                        <FormattedMessage id="pat_table.name" />
                    </PortletTerm>
                    <p id="patient-name-value"><PersonName person={p} /></p>

                    <PortletTerm id="patient-gender-label">
                        <FormattedMessage id="HEADER_SEX" />
                    </PortletTerm>
                    <p id="patient-gender-value">
                        <ProbablyFormattedMessage id={p.sex ? GENDER_MAP[p.sex] : ''} />
                    </p>

                    <PortletTerm id="patient-bdate-label">
                        <FormattedMessage id="HEADER_BDATE" />
                    </PortletTerm>
                    <p id="patient-bdate-value">
                        {p.bdate ? <FormatDate value={p.bdate} date /> : ""}
                    </p>

                    {deployedRussia() && locale == 'ru' && p.payer_id == Payer.PATIENT ? (
                      <>
                          <PortletTerm id="patient-email-label">
                              <FormattedMessage id="PAT_EMAIL" />
                          </PortletTerm>
                          <p data-matomo-mask data-hj-suppress id="patient-email-value">
                              {p.email ? p.email : <FormattedMessage id="None" />}
                          </p>
                      </>
                    ) : null}

                    <PortletTerm id="patient-diagnosis-label">
                        <FormattedMessage id="HEADER_CONDITION" />
                    </PortletTerm>
                    <p data-matomo-mask data-hj-suppress id="patient-diagnosis-value">
                        {p.diagnosis ? newLineToLineBreakTag(p.diagnosis) : ''}
                    </p>

                    {deployedUSA() === true
                        ? (
                            <div>
                                <label className="control-label" id="rx-comment-label">
                                    <strong><FormattedMessage id="EXTRA_COMMENTS" /></strong>
                                </label>
                                <p data-matomo-mask data-hj-suppress id="rx-comment-value">{newLineToLineBreakTag(comment)}</p>
                            </div>
                        ) : null
                    }

                    <h3 className="block" id="clinic-section">
                        <FormattedMessage id="HEADER_CLINIC" />
                    </h3>

                    <PortletTerm id="clinic-name-label">
                        <FormattedMessage id="clin_view.name.placeholder" />
                    </PortletTerm>
                    <p id="clinic-name-value">{p.clinic ? p.clinic.title : ''}</p>

                    <p className="control-label"></p>
                    <h3 className="block" id="payment-section">
                        <FormattedMessage id="BLOCKHEAD_PAYMENT" />
                    </h3>

                    {deployedRussia() && locale === 'ru'
                        ? (
                            <div>
                                <label className="control-label" id="payer-label">
                                    <strong> <FormattedMessage id="HEADER_PAYER" /></strong>
                                </label>
                                <p id="payer-value">
                                    {p.payer_id ? <FormattedMessage id={PAYERS_TRANSLATION_MAP[p.payer_id]} /> : <FormattedMessage id="None" />}
                                </p>
                            </div>
                        ) : null
                    }
                    <label className="control-label" id="payment-method-label">
                        <strong> <FormattedMessage id="HEADER_PAYMENT_METHOD" /></strong>
                    </label>
                    <p id="payment-method-value">
                        {deployedRussia() === true
                            ? <ProbablyFormattedMessage id={p.course.payment_method} />
                            : <ProbablyFormattedMessage id={p.course.payment_method == PaymentMethod.BANK ? "PM_INVOICE" : p.course.payment_method} />
                        }
                    </p>

                    {deployedRussia() === true
                        ? (
                            <div>
                                <label className="control-label" id="payment-option-label">
                                    <strong> <FormattedMessage id="HEADER_PAYMENT_OPTION" /></strong>
                                </label>
                                <div id="payment-option-value">
                                    {p.sber_credit === true
                                        ? <SBCreditComponent showConditions={false} />
                                        : <ProbablyFormattedMessage id={p.course.payment_option} />
                                    }
                                </div>
                                <br />
                            </div>
                        ) : null
                    }

                    {p.logistics_comment
                        ? (
                            <div>
                                <label className="control-label" id="payment-option-label">
                                    <strong> <FormattedMessage id="CLINIC_SHIP_COMMENTS" /></strong>
                                </label>
                                <p id="payment-option-value">{p.logistics_comment}</p>
                            </div>
                        ) : null
                    }

                    {old_instructions && old_instructions.anthropometric_diagnostic
                        || (
                            services && Array.isArray(services) && services.length > 0
                            || (p.course && (
                                p.course.course_option_tag === CO_TAG_FULL_3_STEPS_CBCT || p.course.course_option_tag === CO_TAG_TEEN_3_STEPS_CBCT
                            )
                            )
                        ) ? (
                        <div style={{ marginBottom: "20px" }}>
                            <h3 className="block"><FormattedMessage id="ADDITIONAL_INSTRUCTIONS" /></h3>
                            {old_instructions && old_instructions.anthropometric_diagnostic
                                ? <label className="control-label"><FormattedMessage id="C_ANTHROPOMETRIC_DIAGNOSTIC_ITEM" /></label>
                                : null
                            }
                            {this.renderExtraServices()}
                        </div>
                    ) : null
                    }

                    <div>
                        <label className="control-label" id="course-label">
                            <strong>
                                <FormattedMessage id="HEADER_INSTRUCTIONS" />
                            </strong>
                        </label>
                        <p id="course-value">
                            <CourseName patient={this.props.patient} />
                        </p>
                    </div>

                    <h3 className="col" id="course-section">
                        <FormattedMessage id="BLOCKHEAD_INSTRUCTIONS" />
                    </h3>

                    <div style={{ display: "flex", flexWrap: "wrap", gap: 5 }}>
                        {p.course.correction.map((item, index) => {
                                const style = index === this.state.selected ? 'selected' : 'select_button';
                                return (
                                    <button
                                        onClick={this.handleChange.bind(this, index)}
                                        style={{ color: "#ffffff", border: "none" }}
                                        className={`btn ${style}`}
                                        key={index + item.id}
                                    >
                                        {index === 0
                                            ? <FormattedMessage id="main.treatment" />
                                            : <div>
                                                <FormattedMessage
                                                  id={isChildrenShort ? "pat.block.right.additional_course" : "correction"}
                                                />
                                                {' ' + index}
                                              </div>
                                        }
                                    </button>
                                )
                            })
                        }
                    </div>
                    <br />

                    {instructions === null
                        ? <div><FormattedMessage id="nocorrection" /></div>
                        : (
                            <div>
                                {deployedUSA() && rxTypeId === RxType.SHORT
                                    ? (
                                        <div>
                                            {instructions && instructions.material
                                                ? <FormattedMessage id={translateRxOption('material', instructions.material)} />
                                                : null
                                            }

                                            {!isChildrenShort && hasDeepCBCT(this.props.patient) && hasCorrection ? (
                                              <p style={{ marginTop: 15, marginBottom: 15 }}>
                                                <FormattedMessage id={lastCorrection.with_ct ? "C_YES_CT_CORRECTION" : "C_NO_CT_CORRECTION"} />
                                              </p>
                                            ) : null}

                                            <div>
                                                <h4 style={{ fontWeight: "500" }} className="block" id="treat-arches-section">
                                                    <FormattedMessage id="HEADER_ARCHES" />
                                                </h4>
                                                <label className="control-label" id="treat-arches-label">
                                                    <strong> <FormattedMessage id="ARCHES_SELECT" /> </strong>
                                                </label>
                                                <p id="treat-arches-value">
                                                    <FormattedMessage id={translateRxOption('arch', instructions.arch)} />
                                                </p>
                                            </div>

                                            <PatientTeethItemsReadOnly
                                                label="EXTRA_NOLOCK"
                                                name="avoid_locks"
                                                defaultValue={instructions ? instructions.avoid_locks : []}
                                                notation={user.preferences.dental_notation}
                                                key={this.state.avoid_locks}
                                            />
                                            <PatientTeethItemsReadOnly
                                                label="EXTRA_EXTRACT"
                                                name="extractions"
                                                defaultValue={instructions ? instructions.extractions : []}
                                                notation={user.preferences.dental_notation}
                                                key={this.state.extractions}
                                            />
                                        </div>
                                    ) :
                                    (
                                        <div>
                                            {instructions && instructions.material
                                                ? <FormattedMessage id={translateRxOption('material', instructions.material)} />
                                                : null
                                            }

                                            {canOrderTestPlastic(this.props.user) && lastCorrection.test_plastic != null ? (
                                                <p style={{ marginTop: 15, marginBottom: 15 }}>
                                                  <FormattedMessage id={lastCorrection.test_plastic ? "C_TEST_PLASTIC_YES" : "C_TEST_PLASTIC_NO"} />
                                                </p>
                                            ) : null}

                                            {!isChildrenShort && hasDeepCBCT(this.props.patient) && hasCorrection ? (
                                                <p style={{ marginTop: 15, marginBottom: 15 }}>
                                                  <FormattedMessage id={lastCorrection.with_ct ? "C_YES_CT_CORRECTION" : "C_NO_CT_CORRECTION"} />
                                                </p>
                                            ) : null}

                                            <div>
                                                <h4 style={{ fontWeight: "500" }} className="block" id="treat-arches-section">
                                                    <FormattedMessage id="HEADER_ARCHES" />
                                                </h4>
                                                <label className="control-label" id="treat-arches-label">
                                                    <strong> <FormattedMessage id="ARCHES_SELECT" /> </strong>
                                                </label>
                                                <p id="treat-arches-value">
                                                    <FormattedMessage id={translateRxOption('arch', instructions.arch)} />
                                                </p>
                                            </div>

                                            {isRetainersCourse(p.course.course_id) === false
                                                ? (
                                                    <div>
                                                        <label className="control-label" id="upper-arch-action-label">
                                                            <strong> <FormattedMessage id="ARCHES_U_SELECT" /> </strong>
                                                        </label>

                                                        {this.renderExpansion("upper")}
                                                        <label className="control-label">
                                                            <strong> <FormattedMessage id="ARCHES_L_SELECT" /></strong>
                                                        </label>

                                                        {this.renderExpansion("lower")}
                                                        {p.course.course !== "C_CHILDREN" &&
                                                            <div>
                                                                <label className="control-label" id="teeth-level-label">
                                                                    <strong><FormattedMessage id="ARCHES_TLA" /></strong>
                                                                </label>
                                                                <p id="teeth-level-value">
                                                                    <FormattedMessage id={translateRxOption('teeth_level_align', instructions.teeth_level_align)} />
                                                                </p>
                                                            </div>
                                                        }

                                                        <h4 style={{ fontWeight: "500" }} className="block" id="incisors-section">
                                                            <FormattedMessage id="HEADER_INCISORS" />
                                                        </h4>
                                                        <label className="control-label" id="incisors-vert-overlap-label">
                                                            <strong> <FormattedMessage id="INCISORS_VO" /></strong>
                                                        </label>
                                                        <p id="incisors-vert-overlap-value">
                                                            <FormattedMessage id={translateRxOption('vertical_overlap', instructions.vertical_overlap)} />
                                                        </p>
                                                        {instructions.vertical_overlap === 1 || instructions.vertical_overlap === 2 && instructions.vertical_overlap_comment
                                                            ? (
                                                                <div style={{ marginLeft: '20px' }}>
                                                                    <label className="control-label" id="rx-comment-label" style={{ fontWeight: '600', fontStyle: "italic" }}>
                                                                        <FormattedMessage id="VO_COMMENT" />:
                                                                    </label>
                                                                    <p>{instructions.vertical_overlap_comment}</p>
                                                                </div>
                                                            ) : null
                                                        }

                                                        {p.course.course !== "C_CHILDREN" &&
                                                            <div>

                                                                <div>
                                                                    {instructions.upper_horizontal_overlap !== null || instructions.lower_horizontal_overlap != null ? (
                                                                        <div>
                                                                            <label className="control-label" id="incisors-horiz-overlap-label">
                                                                                <strong><FormattedMessage id="INCISORS_HO" /></strong>
                                                                            </label>
                                                                            <div className='row'>
                                                                            <div className="form-group col-md-4">
                                                                                <label className="control-label">
                                                                                    <strong><FormattedMessage id="INCISORS_HO_U" /></strong>
                                                                                </label>
                                                                                <p id="incisors-upper-horiz-overlap-value">
                                                                                    <FormattedMessage id={translateRxOption('upper_horizontal_overlap', instructions.upper_horizontal_overlap)} />
                                                                                </p>
                                                                            </div>
                                                                            <div className="form-group col-md-4">
                                                                                <label className="control-label">
                                                                                    <strong> <FormattedMessage id="INCISORS_HO_L" /></strong>
                                                                                </label>
                                                                                <p id="incisors-lower-horiz-overlap-value">
                                                                                    <FormattedMessage id={translateRxOption('lower_horizontal_overlap', instructions.lower_horizontal_overlap)} />
                                                                                </p>
                                                                            </div>
                                                                            </div>
                                                                        </div>
                                                                    ) : null
                                                                    }
                                                                    {instructions.overjet === 3 ? (
                                                                        <div className='row'>
                                                                        <div className="form-group col-md-8">
                                                                            <label className="control-label">
                                                                                <strong> <FormattedMessage id="OVERJET" /></strong>
                                                                            </label>
                                                                            <p id="overjet-value">
                                                                                <FormattedMessage id={translateRxOption('overjet', instructions.overjet)} />
                                                                            </p>
                                                                            <ul style={{marginBottom: "20px"}} id="overjet-value-options">
                                                                                {correct_retroclination_upper ? <li><FormattedMessage id='CORRECT_RETROCLINATION_UPPER' /></li> : null}
                                                                                {correct_proclination_lower ? <li><FormattedMessage id='CORRECT_PROCLINATION_LOWER' /></li> : null}
                                                                                {correct_proclination_upper ? <li><FormattedMessage id='CORRECT_PROCLINATION_UPPER' /></li> : null}
                                                                                {correct_retroclination_lower ? <li><FormattedMessage id='CORRECT_RETROCLINATION_LOWER' /></li> : null}
                                                                            </ul>
                                                                        </div>
                                                                        </div>
                                                                    ) : (
                                                                        <div className='row'>
                                                                        <div className="form-group col-md-4">
                                                                            <label className="control-label">
                                                                                <strong> <FormattedMessage id="OVERJET" /></strong>
                                                                            </label>
                                                                            <p id="overjet-value">
                                                                                <FormattedMessage id={translateRxOption('overjet', instructions.overjet)} />
                                                                            </p>
                                                                        </div>
                                                                        </div>
                                                                    )}
                                                                </div>

                                                                <label className="control-label" id="midline-label">
                                                                    <strong> <FormattedMessage id="MIDLINE" /> </strong>
                                                                </label>
                                                                <div id="midline-value">
                                                                    <FormattedMessage id={translateRxOption('midline', instructions.midline)} />
                                                                    {instructions.midline === 1
                                                                        ? (
                                                                            <p style={{ marginLeft: '20px' }}>
                                                                                <br />{instructions.midline_improve_by}
                                                                            </p>
                                                                        ) : null
                                                                    }
                                                                </div>

                                                                <h4 style={{ fontWeight: "500" }} className="block"><FormattedMessage id="HEADER_CANINE" /></h4>
                                                                <div className="row">
                                                                    <div className="form-group col-md-3">
                                                                        <label className="control-label" id="canines-ratio-right-label">
                                                                            <strong> <FormattedMessage id="CANINE_RIGHT" /></strong>
                                                                        </label>
                                                                        <p id="canines-ratio-right-value">
                                                                            <FormattedMessage id={translateRxOption('canine_ratio_right', instructions.canine_ratio_right)} />
                                                                        </p>
                                                                    </div>
                                                                    <div className="form-group col-md-3">
                                                                        <label className="control-label" id="canines-ratio-left-label">
                                                                            <strong> <FormattedMessage id="CANINE_LEFT" /></strong>
                                                                        </label>
                                                                        <p id="canines-ratio-left-value">
                                                                            <FormattedMessage id={translateRxOption('canine_ratio_left', instructions.canine_ratio_left)} />
                                                                        </p>
                                                                    </div>
                                                                    {
                                                                        instructions.canine_method_ai === true ? (
                                                                            <div className="form-group col-lg-4 col-sm-6">
                                                                                <label className="control-label" id="canines-ratio-howto-label">
                                                                                    <strong><FormattedMessage id="CANINE_HOWTO" /></strong>
                                                                                </label>
                                                                                <p style={{ marginTop: '10px' }}>
                                                                                    <FormattedMessage id={translateRxOption('canine_method_ai', instructions.canine_method_ai)} />
                                                                                </p>
                                                                            </div>
                                                                        ) : (
                                                                            <div>
                                                                                <div className="form-group col-md-3">
                                                                                    <label className="control-label" id="canines-ratio-howto-label">
                                                                                        <strong><FormattedMessage id="CANINE_HOWTO" /></strong>
                                                                                    </label>
                                                                                    <p id="canines-ratio-howto-value">
                                                                                        <FormattedMessage id={translateRxOption('canine_method', instructions.canine_method)} />
                                                                                    </p>
                                                                                </div>
                                                                                {
                                                                                    instructions.canine_ipr === true &&
                                                                                    <div className="form-group col-lg-3 col-sm-5">
                                                                                        <p style={{ marginTop: '10px' }}>
                                                                                            <FormattedMessage id={translateRxOption('canine_ipr', instructions.canine_ipr)} />
                                                                                        </p>
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                        )
                                                                    }
                                                                </div>
                                                            </div>
                                                        }


                                                        <h4 style={{ fontWeight: "500" }} className="block" id="molars-section">
                                                            <FormattedMessage id="HEADER_MOLAR" />
                                                        </h4>

                                                        {p.course.course !== "C_CHILDREN"
                                                            ? (
                                                                <div className="row">
                                                                    <div className="form-group col-md-3">
                                                                        <label className="control-label" id="molars-ratio-right-label">
                                                                            <strong> <FormattedMessage id="MOLAR_RIGHT" /></strong>
                                                                        </label>
                                                                        <p id="molars-ratio-right-value">
                                                                            <FormattedMessage id={translateRxOption('molars_ratio_right', instructions.molars_ratio_right)} />
                                                                        </p>
                                                                    </div>
                                                                    <div className="form-group col-md-3">
                                                                        <label className="control-label" id="molars-ratio-left-label">
                                                                            <strong><FormattedMessage id="MOLAR_LEFT" /></strong>
                                                                        </label>
                                                                        <p id="molars-ratio-left-value">
                                                                            <FormattedMessage id={translateRxOption('molars_ratio_left', instructions.molars_ratio_left)} />
                                                                        </p>
                                                                    </div>
                                                                    {instructions.molars_method_ai === true ? (
                                                                        <div className="form-group col-lg-4 col-sm-6">
                                                                            <label className="control-label" id="molars-ratio-howto-label">
                                                                                <strong> <FormattedMessage id="MOLAR_HOWTO" /></strong>
                                                                            </label>
                                                                            <p style={{ marginTop: '10px' }}>
                                                                                <FormattedMessage id={translateRxOption('molars_method_ai', instructions.molars_method_ai)} />
                                                                            </p>
                                                                        </div>
                                                                    ) : (
                                                                        <div>
                                                                            <div className="form-group col-md-3">
                                                                                <label className="control-label" id="molars-ratio-howto-label">
                                                                                    <strong> <FormattedMessage id="MOLAR_HOWTO" /></strong>
                                                                                </label>
                                                                                <p id="molars-ratio-howto-value">
                                                                                    <FormattedMessage id={translateRxOption('molars_method', instructions.molars_method)} />
                                                                                </p>
                                                                            </div>
                                                                            {instructions.molars_ipr === true &&
                                                                                <div className="form-group col-lg-3 col-sm-5">
                                                                                    <p style={{ marginTop: '10px' }}>
                                                                                        <FormattedMessage id={translateRxOption('molars_ipr', instructions.molars_ipr)} />
                                                                                    </p>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    )
                                                                    }
                                                                </div>
                                                            ) : (
                                                                <div className="row">
                                                                    <div className="form-group col-md-4">
                                                                        <p><FormattedMessage id={translateRxOption('molars_ratio_right', instructions.molars_ratio_right)} /></p>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }

                                                        {p.course.course === "C_CHILDREN" &&
                                                            <PatientTeethItemsReadOnly
                                                                label="EXTRA_TEETHING"
                                                                name="teething"
                                                                defaultValue={instructions ? instructions.teething : []}
                                                                notation={user.preferences.dental_notation}
                                                                key={this.state.teething}
                                                            />
                                                        }

                                                        {p.course.course !== "C_CHILDREN" &&
                                                            <div>
                                                                <label className="control-label" id="close-all-gaps-label">
                                                                    <strong> <FormattedMessage id="HEADER_CLOSE_ALL" /></strong>
                                                                </label>
                                                                <div style={{ marginBottom: "20px" }} id="close-all-gaps-value">
                                                                    <p style={{ fontWeight: "600", marginBottom: "5px" }}>
                                                                        <FormattedMessage id={translateRxOption('close_all_gaps', instructions.close_all_gaps)} />
                                                                    </p>
                                                                    {instructions.close_all_gaps === 3 && (instructions.close_all_gaps_value || close_all_gaps_spaces)
                                                                        ? (
                                                                            <div>
                                                                                <p style={{
                                                                                    fontStyle: "italic",
                                                                                    marginBottom: "15px",
                                                                                    marginLeft: "30px",
                                                                                    wordBreak: "break-word",
                                                                                    whiteSpace: "pre-line"
                                                                                }}>
                                                                                    {instructions.close_all_gaps_value}
                                                                                </p>
                                                                                {Object.keys(close_all_gaps_spaces).length > 0
                                                                                    ? (
                                                                                        <div>
                                                                                            <label className="control-label" id="close-all-gaps-label" style={{ fontWeight: '600' }}>
                                                                                                <FormattedMessage id="CAG_LEAVE_SPACE_FOR" />
                                                                                            </label>
                                                                                            {(Object.entries(close_all_gaps_spaces)).map(([key, value]) => {
                                                                                                return (
                                                                                                    <div key={key}>
                                                                                                        <div>
                                                                                                            <span>
                                                                                                                <FormattedMessage id="TOOTH_NUMBER" />&nbsp;{`${convertToDentalNotation(key, dentalNotation)} -`}
                                                                                                            </span>
                                                                                                            &nbsp;{`${value}`}&nbsp;<span><FormattedMessage id="MILLIMETRES" /></span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                )
                                                                                            })}
                                                                                        </div>
                                                                                    ) : null
                                                                                }
                                                                            </div>
                                                                        ) : null
                                                                    }
                                                                </div>

                                                                <label className="control-label"><strong> <FormattedMessage id="HEADER_ELASTICS" /></strong></label>
                                                                <div className="row">
                                                                    <div className="form-group col-md-4">
                                                                        <label className="control-label" id="elastics-ratio-right-label">
                                                                            <strong><FormattedMessage id="ELASTICS_RIGHT" /></strong>
                                                                        </label>
                                                                        <p id="elastics-ratio-right-value">
                                                                            <FormattedMessage id={translateRxOption('elastics_recoil_right', instructions.elastics_recoil_right)} />
                                                                        </p>
                                                                    </div>
                                                                    <div className="form-group col-md-4">
                                                                        <label className="control-label" id="elastics-ratio-left-label">
                                                                            <strong><FormattedMessage id="ELASTICS_LEFT" /></strong>
                                                                        </label>
                                                                        <p id="elastics-ratio-left-value">
                                                                            <FormattedMessage id={translateRxOption('elastics_recoil_left', instructions.elastics_recoil_left)} />
                                                                        </p>
                                                                    </div>
                                                                </div>

                                                                <PatientElasticsOptionsTeethItemsReadOnly
                                                                    label="BUTTONS_AND_HOOKS"
                                                                    name="elastics_options"
                                                                    defaultValue={instructions ? elastics : []}
                                                                    notation={user.preferences.dental_notation}
                                                                    instructions={instructions}
                                                                    key={this.state.elastics}
                                                                />
                                                                <PatientTeethItemsReadOnly
                                                                    label="EXTRA_NOMOVE"
                                                                    name="dont_move"
                                                                    defaultValue={instructions ? instructions.dont_move : []}
                                                                    notation={user.preferences.dental_notation}
                                                                    key={this.state.dont_move}
                                                                />
                                                                <PatientTeethItemsReadOnly
                                                                    label="EXTRA_NOLOCK"
                                                                    name="avoid_locks"
                                                                    defaultValue={instructions ? instructions.avoid_locks : []}
                                                                    notation={user.preferences.dental_notation}
                                                                    key={this.state.avoid_locks}
                                                                />
                                                                <PatientTeethItemsReadOnly
                                                                    label="EXTRA_EXTRACT"
                                                                    name="extractions"
                                                                    defaultValue={instructions ? instructions.extractions : []}
                                                                    notation={user.preferences.dental_notation}
                                                                    key={this.state.extractions}
                                                                />
                                                                <PatientTeethItemsReadOnly
                                                                    label="EXTRA_HOOK"
                                                                    name="hooks"
                                                                    defaultValue={p.instructions ? p.instructions.hooks : []}
                                                                    notation={user.preferences.dental_notation}
                                                                    key={this.state.hooks}
                                                                />
                                                                <PatientTeethItemsReadOnly
                                                                    label="EXTRA_BUTTON"
                                                                    name="buttons"
                                                                    defaultValue={p.instructions ? p.instructions.buttons : []}
                                                                    notation={user.preferences.dental_notation}
                                                                    key={this.state.buttons}
                                                                />
                                                            </div>
                                                        }
                                                    </div>
                                                ) : <div><br /></div>
                                            }

                                            {deployedRussia() ? (
                                                <div>
                                                    <label className="control-label" id="rx-comment-label">
                                                        <strong>
                                                          <FormattedMessage id="EXTRA_COMMENTS" />
                                                        </strong>
                                                    </label>
                                                    <p
                                                      id="rx-comment-value"
                                                      style={{ wordBreak: "break-word" }}
                                                      data-matomo-mask data-hj-suppress
                                                    >
                                                      {newLineToLineBreakTag(comment)}
                                                    </p>
                                                </div>
                                            ) : null}
                                        </div>
                                    )}

                            </div>
                        )
                    }


                    {links && links.length > 0 &&
                        <div>
                            <h4 style={{ fontWeight: "500" }}><FormattedMessage id="CBCT_LINKS" /></h4>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th><FormattedMessage id="LINK" /></th>
                                        <th className="link_created_date"><FormattedMessage id="CREATED_AT" /></th>
                                        <th><FormattedMessage id="OPEN" /></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {links.map((item, index) => {
                                        return (
                                            <tr key={index + item.timestamp}>
                                                <td>
                                                    <div className="input-group">
                                                        <input
                                                            data-matomo-mask
                                                            type="text"
                                                            className="form-control"
                                                            readOnly={true}
                                                            value={item.link}
                                                            name={item} />
                                                    </div>
                                                </td>
                                                <td className="link_created_date" style={{ lineHeight: "35px" }}>
                                                    {<FormatDate value={item.timestamp} date={true} time={true} />}
                                                </td>
                                                <td>
                                                    <span className="input-group-btn">
                                                        <a
                                                            className="btn btn-default"
                                                            href={item.link}
                                                            onClick={e => (e.preventDefault(), navigator.clipboard.writeText(`${item.link}`))}
                                                        ><span className="glyphicon glyphicon-copy"></span>
                                                        </a>
                                                    </span>
                                                </td>
                                            </tr>
                                        )
                                    })
                                    }
                                </tbody>
                            </table>
                        </div>
                    }

                    <form
                        id="fileupload extra-media-block"
                        method="post"
                        encType="multipart/form-data"
                        data-upload-template-id="template-upload-2" data-download-template-id="template-download-2"
                    >
                        <div className="form-body">
                            <h4 style={{ fontWeight: "500" }} className="block"><FormattedMessage id="UPLOADED_FILES" /></h4>
                            {/* The table listing the files available for upload/download */}
                            <table
                                role="presentation"
                                className="table table-striped clearfix"
                                style={{ wordBreak: 'break-all' }} cellSpacing={0} cellPadding={0} width="100%"
                            >
                                <tbody className="files">
                                    {pmo.map((m) => <OptionalMedia key={m.linkto} prefix={pfx} media={m} />)}
                                    {pmp.map((m) => <OptionalMedia key={m.linkto} prefix={pfx} media={m} />)}
                                </tbody>
                            </table>
                            {!!this.props.user.privileges.has_3shape_scanner
                                ? <div id="3shape-media-block"><PatientFiles type={FILE_TYPE_3SHAPE} /></div>
                                : null
                            }
                            <div id="medit-media-block"><PatientFiles type={FILE_TYPE_MEDIT} /></div>
                        </div>
                    </form>

                    {isRetainersCourse(p.course.course_id) === false
                        ? (
                            <div className="form-body" id="ct-media-block">
                                <h4 style={{ fontWeight: "500" }} className="block"><FormattedMessage id="UPLOADED_CBCT" /></h4>
                                <PatientFiles type={FILE_TYPE_DICOM} />
                                {deployedUSA() ? <div id="prexion-media-block"><PatientFiles type={FILE_TYPE_PREXION} /></div> : null}
                            </div>
                        ) : null
                    }

                    {isRetainersCourse(p.course.course_id) === false
                        ? (
                            <div>
                                <h3 className="block" id="photo-protocol-block">
                                    <FormattedMessage id="PHOTO_BEFORE" />
                                </h3>
                                <div className="row">
                                    <div className="col-md-4" id="photo-profile">
                                        <div className="form-group">
                                            <label className="control-label">
                                                <FormattedMessage id="PHOTO_PROFILE" />
                                            </label>
                                            <br />
                                            <RequiredPhoto prefix={pfx} view={pmi.profile} />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group" id="photo-front-smile">
                                            <label className="control-label">
                                                <FormattedMessage id="PHOTO_SMILE" />
                                            </label>
                                            <br />
                                            <RequiredPhoto prefix={pfx} view={pmi.full_face_with_smile} />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group" id="photo-front-no-smile">
                                            <label className="control-label">
                                                <FormattedMessage id="PHOTO_PORTRATE" />
                                            </label>
                                            <br />
                                            <RequiredPhoto prefix={pfx} view={pmi.full_face_without_smile} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="form-group" id="photo-upper-occlusal">
                                            <label className="control-label">
                                                <FormattedMessage id="PHOTO_UPPER_OCCLUSAL" />
                                            </label>
                                            <br />
                                            <RequiredPhoto prefix={pfx} view={pmi.occlusal_view_upper} />
                                        </div>
                                    </div>
                                    <div className="col-md-4" />
                                    <div className="col-md-4">
                                        <div className="form-group" id="photo-lower-occlusal">
                                            <label className="control-label">
                                                <FormattedMessage id="PHOTO_LOWER_OCCLUSAL" />
                                            </label>
                                            <br />
                                            <RequiredPhoto prefix={pfx} view={pmi.occlusal_view_lower} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="form-group" id="photo-lateral-right">
                                            <label className="control-label">
                                                <FormattedMessage id="PHOTO_RIGHT" />
                                            </label>
                                            <br />
                                            <RequiredPhoto prefix={pfx} view={pmi.lateral_view_right} />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group" id="photo-intraoral-front">
                                            <label className="control-label">
                                                <br /><FormattedMessage id="PHOTO_FRONT" />
                                            </label>
                                            <br />
                                            <RequiredPhoto prefix={pfx} view={pmi.front_view} />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group" id="photo-lateral-left">
                                            <label className="control-label">
                                                <FormattedMessage id="PHOTO_LEFT" />
                                            </label>
                                            <br />
                                            <RequiredPhoto prefix={pfx} view={pmi.lateral_view_left} />
                                        </div>
                                    </div>
                                </div>
                                {Object.keys(p.media.correction_media) === 0 ? null : <PatientCorrectionPhoto patient={this.props.patient} />}
                            </div>
                        ) : null
                    }

                    <div className="form-actions">
                        <Button variant="grey" asChild>
                            <NavLink to={"/pages/edit_patient/" + this.props.patient.patient_id}>
                                <FormattedMessage id="BTN_EDIT" />
                            </NavLink>
                        </Button>
                    </div>

                </div>
            </Portlet>
        )
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(PatientCard);

export function CourseName({
  patient
}: {
  patient: Pick<TPatientList, "course" | "course_version">
}) {
  const intl = useIntl();

  if (deployedRussia()) {
    if (intl.locale == 'ru') {
      if (patient.course_version == COURSE_VERSION_V2) {
        return <ProbablyFormattedMessage id={`${patient.course.course}_V2`} />;
      } else if (patient.course_version == COURSE_VERSION_V3) {
        return <ProbablyFormattedMessage id={`${patient.course.course}_V3`} />;
      }
    }
    return <ProbablyFormattedMessage id={patient.course.course} />;
  }

  return <ProbablyFormattedMessage id={`${patient.course.course}_USA`} />;
}
