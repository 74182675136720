import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import { Button } from "~/components/ui/button";
import { Layout } from "~/components/ui/layout";
import { Portlet } from "~/components/ui/portlet";
import { useIntlDocumentTitle } from "~/hooks/use-document-title";

import imageOneDesktop from "./excursion-desktop-1.jpg";
import imageTwoDesktop from "./excursion-desktop-2.jpg";
import imageThreeDesktop from "./excursion-desktop-3.jpg";
import imageOneMobile from "./excursion-mobile-1.png";
import imageTwoMobile from "./excursion-mobile-2.png";
import imageThreeMobile from "./excursion-mobile-3.png";

export function ExcursionPage() {
  useIntlDocumentTitle("excursion.title");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (
    <Layout>
      <Portlet rounded as="main" className="tw-px-0">
        <div className="tw-flex tw-flex-col tw-gap-12 tw-py-4 [&_p]:tw-my-0">
          <div className="tw-px-6">
            <picture>
              <source media="(max-width: 600px)" srcSet={imageOneMobile} />
              <img className="tw-w-full tw-rounded-2xl" height={240} src={imageOneDesktop} alt="" />
            </picture>
          </div>

          <div className="tw-flex tw-flex-col tw-gap-6 tw-px-6">
            <p className="sm:tw-text-center">Уважаемый доктор!</p>

            <h2 className="tw-mx-auto tw-my-0 tw-max-w-[600px] tw-text-xl tw-font-semibold tw-leading-normal sm:tw-text-center">
              Приглашаем Вас на индивидуальную онлайн-экскурсию по личному кабинету 3D Smile и 3D Precise
            </h2>

            <div className="tw-mx-auto max-sm:tw-w-full">
              <Button variant="primary" className="max-sm:tw-w-full" rounded asChild>
                <Link to="/pages/excursion/book">Записаться</Link>
              </Button>
            </div>

            <p className="tw-leading-9">
              Мы знаем, как важно чувствовать поддержку при освоении новых инструментов, поэтому
              стремимся сделать Ваше знакомство с личным кабинетом максимально комфортным и легким.
            </p>

            <p className="tw-leading-9">
              <span className="tw-font-semibold">Эксперт 3D Smile Ольга Макарова</span> познакомит
              Вас с интерфейсом, функционалом, полезными лайфхаками для оптимизации работы в личном
              кабинете, а также продемонстрирует возможности новейшего программного обеспечения для
              ортодонтов — 3D Precise
            </p>

            <p>
              <span className="tw-font-semibold">Экскурсия состоит из двух блоков</span>, в ходе
              встречи Вы сможете задать любые вопросы эксперту.
            </p>
          </div>

          <div className="tw-flex tw-flex-col tw-gap-8 tw-bg-[#f6f6f8] tw-px-6 tw-py-10 sm:tw-flex-row">
            <div className="tw-flex tw-grow tw-flex-col tw-gap-5">
              <picture>
                <source media="(max-width: 600px)" srcSet={imageTwoMobile} />
                <img className="tw-w-full tw-rounded-2xl" src={imageTwoDesktop} alt="" />
              </picture>

              <div className="tw-flex tw-flex-col tw-gap-3">
                <h3 className="tw-my-0 tw-text-xl tw-font-semibold sm:tw-text-center">
                  Блок 1: Личный кабинет
                </h3>

                <ul className="tw-max-w-sm tw-px-6 tw-leading-9">
                  <li>Интерфейс, панель инструментов и разделы меню</li>
                  <li>Заведение пациента и заполнение рецепта</li>
                  <li>Отслеживание статусов</li>
                  <li>
                    Полезные сервисы для врача: Онлайн-консультация клинического эксперта,
                    Экспресс-поддержка руководителя отдела моделирования, Служба заботы.
                  </li>
                  <li>Образовательные мероприятия</li>
                  <li>Библиотека клинических случаев 3D Smile</li>
                </ul>
              </div>
            </div>

            <div className="tw-flex tw-grow tw-flex-col tw-gap-5">
              <picture>
                <source media="(max-width: 600px)" srcSet={imageThreeMobile} />
                <img className="tw-w-full tw-rounded-2xl" src={imageThreeDesktop} alt="" />
              </picture>

              <div className="tw-flex tw-flex-col tw-gap-3">
                <h3 className="tw-my-0 tw-text-xl tw-font-semibold sm:tw-text-center">
                  Блок 2: 3D Precise
                </h3>

                <ul className="tw-max-w-sm tw-px-6 tw-leading-9">
                  <li>Интерфейс и панель инструментов</li>
                  <li>Режимы: просмотр, редактор финального положения зубов, редактор замков</li>
                  <li>Переключатель видов 3D-модели</li>
                  <li>Дополнительные диагностические инструменты</li>
                  <li>Лимиты перемещений</li>
                  <li>Использование фотопротокола при работе с 3D-Планом</li>
                  <li>Отчет о движениях</li>
                  <li>Редактирование сепараций и трем</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="tw-flex tw-w-full tw-items-center tw-justify-between tw-gap-8 tw-px-5 tw-pb-4 max-sm:tw-flex-col sm:tw-px-12">
            <div className="tw-flex tw-flex-col tw-gap-3">
              <h2 className="tw-my-0 tw-max-w-[500px] tw-text-xl tw-font-semibold tw-leading-normal">
                Запишитесь в удобное для Вас время на экскурсию с экспертом 3D Smile
              </h2>

              <div>
                <p className="tw-my-0 tw-text-[#808080]">Формат встречи: Онлайн</p>
                <p className="tw-my-0 tw-text-[#808080]">Продолжительность экскурсии: 1 час</p>
              </div>
            </div>

            <div className="max-sm:tw-w-full">
              <Button variant="primary" className="max-sm:tw-w-full" rounded asChild>
                <Link to="/pages/excursion/book">Записаться</Link>
              </Button>
            </div>
          </div>
        </div>
      </Portlet>
    </Layout>
  );
}
