import clsx from "clsx";
import React from "react";
import { FormattedMessage } from "react-intl";

import { Status } from "~/common/courses";
import type { TPatient } from "~/reducers/dashboard";

export function PatientStatusBadge({ status }: { status: TPatient["status"] }) {
  let className: string;

  switch (status) {
    case Status.TREATMENT_FINISHED:
    case Status.CANCEL_TREATMENT:
      className = "bg-grey tw-bg-gray-400";
      break;
    case Status.WAITING_FOR_A_PAYMENT:
    case Status.UNFILLED_CASE:
    case Status._3D_PLAN_APPROVAL:
    case Status.RETAKE_IMPRESSIONS:
    case Status.RETAINERS_RETAKE_IMPRESSIONS:
    case Status.RETAINERS_WAITING_FOR_A_PAYMENT:
      className = "bg-red";
      break;
    default:
      className = "bg-green";
      break;
  }

  return (
    <span className={clsx("label label-sm tw-rounded-[3px]", className)}>
      <FormattedMessage id={status} />
    </span>
  );
}
