import React from "react";
import { FormattedMessage } from "react-intl";

import { isChildrenShortCourse } from "~/common/courses";
import { API_PATIENT_IMAGE } from "~/config";
import type { TCorrectionMediaView, TPatient } from "~/reducers/dashboard";

export default function PatientCorrectionPhoto({ patient }: { patient: TPatient }) {
  const correction_media = patient.media.correction_media;
  const pfx = API_PATIENT_IMAGE(patient.patient_id, "");

  return (
    <div>
      {Object.entries(correction_media).map(([num, correctionMedia]) => {
        return (
          <div key={num}>
            <h3 className="block">
              <FormattedMessage
                id={
                  isChildrenShortCourse(patient.course.course_id)
                    ? "pat.block.right.additional_course"
                    : "PHOTO_CORRECTION"
                }
              />{" "}
              {num}
            </h3>

            <div className="row">
              <div className="col-md-4">
                <div className="form-group">
                  <label className="control-label">
                    <FormattedMessage id="PHOTO_PROFILE" />
                  </label>
                  <br />
                  <CorrectionPhoto prefix={pfx} view={correctionMedia.profile} />
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group">
                  <label className="control-label">
                    <FormattedMessage id="PHOTO_SMILE" />
                  </label>
                  <br />
                  <CorrectionPhoto prefix={pfx} view={correctionMedia.full_face_with_smile} />
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group">
                  <label className="control-label">
                    <FormattedMessage id="PHOTO_PORTRATE" />
                  </label>
                  <br />
                  <CorrectionPhoto prefix={pfx} view={correctionMedia.full_face_without_smile} />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-4">
                <div className="form-group">
                  <label className="control-label">
                    <FormattedMessage id="PHOTO_UPPER_OCCLUSAL" />
                  </label>
                  <br />
                  <CorrectionPhoto prefix={pfx} view={correctionMedia.occlusal_view_upper} />
                </div>
              </div>

              <div className="col-md-4" />

              <div className="col-md-4">
                <div className="form-group">
                  <label className="control-label">
                    <FormattedMessage id="PHOTO_LOWER_OCCLUSAL" />
                  </label>
                  <br />
                  <CorrectionPhoto prefix={pfx} view={correctionMedia.occlusal_view_lower} />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-4">
                <div className="form-group">
                  <label className="control-label">
                    <FormattedMessage id="PHOTO_RIGHT" />
                  </label>
                  <br />
                  <CorrectionPhoto prefix={pfx} view={correctionMedia.lateral_view_right} />
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group">
                  <label className="control-label">
                    <br />
                    <FormattedMessage id="PHOTO_FRONT" />
                  </label>
                  <br />
                  <CorrectionPhoto prefix={pfx} view={correctionMedia.front_view} />
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group">
                  <label className="control-label">
                    <FormattedMessage id="PHOTO_LEFT" />
                  </label>
                  <br />
                  <CorrectionPhoto prefix={pfx} view={correctionMedia.lateral_view_left} />
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

function CorrectionPhoto({
  prefix,
  view,
}: {
  prefix: string;
  view: TCorrectionMediaView | undefined;
}) {
  if (!view) {
    return null;
  }

  return (
    <a href={prefix + view.linkto} target="_blank" rel="noreferrer">
      <img src={prefix + view.thumbnail + `?${Number(new Date())}`} width="100%" alt="" />
    </a>
  );
}
