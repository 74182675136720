import React, { useCallback, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";

import { useUser } from "~/common/user";
import Loader from "~/components/common/loadingInProgress";
import {
  changeBookButton,
  changeBookText,
  changeContinueButton,
  fillInForm,
  isBackButton,
  isSelectButton,
} from "~/components/doctor/meetings/cabinet-widget";
import { Layout } from "~/components/ui/layout";
import { Portlet } from "~/components/ui/portlet";
import { useIntlDocumentTitle } from "~/hooks/use-document-title";
import { useScript } from "~/hooks/use-script";

export function ExcursionCabinetWidgetPage() {
  useIntlDocumentTitle("excursion.title");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  const user = useUser();
  const containerRef = useRef<HTMLDivElement>(null);
  const scriptStatus = useScript("https://cabinet.fm/external/widget.js");
  const history = useHistory();

  const onBackClick = useCallback(() => {
    history.push("/pages/excursion");
  }, [history]);

  useEffect(() => {
    const isCabinetWidgetScriptLoaded = typeof Cabinet != "undefined";
    if (!isCabinetWidgetScriptLoaded) {
      return;
    }

    if (typeof MutationObserver == "undefined") {
      return;
    }

    let backButton: HTMLDivElement | undefined;

    function handleBackButtonClick(event: MouseEvent) {
      event.stopPropagation();
      onBackClick();
    }

    const observer = new MutationObserver((mutationList) => {
      for (const mutation of mutationList) {
        if (mutation.type == "childList" && mutation.addedNodes.length > 0) {
          const addedNode = mutation.addedNodes[0];

          if (addedNode instanceof HTMLElement) {
            addedNode.querySelectorAll("button").forEach((button) => {
              if (isSelectButton(button)) {
                button.click();
              } else {
                changeContinueButton(button);
                changeBookButton(button);
              }
            });
          }

          if (addedNode instanceof HTMLButtonElement) {
            changeBookButton(addedNode);
          }

          if (addedNode instanceof HTMLDivElement) {
            changeBookText(addedNode);

            if (isBackButton(addedNode)) {
              if (backButton) {
                onBackClick();
              } else {
                backButton = addedNode;
                backButton.addEventListener("click", handleBackButtonClick);
              }
            }
          }

          if (addedNode instanceof HTMLFormElement) {
            const form = addedNode;
            fillInForm(form, user);

            if (backButton) {
              backButton.removeEventListener("click", handleBackButtonClick);
            }
          }
        }
      }
    });

    if (containerRef.current) {
      observer.observe(containerRef.current, { subtree: true, childList: true });
    }

    Cabinet.init({
      container: "#cabinet-container",
      company: parseInt(process.env.CABINET_COMPANY, 10),
      id: parseInt(process.env.CABINET_EXCURSION_SERVICE_ID, 10),
      locale: "ru",
    });

    return () => {
      observer?.disconnect();
    };
  }, [scriptStatus, user, onBackClick]);

  return (
    <Layout>
      <Portlet rounded as="main" className="tw-px-0">
        {scriptStatus == "loading" ? <Loader /> : null}

        <div
          id="cabinet-container"
          ref={containerRef}
          style={
            {
              "--cb-primary-bg": "#32c5d2",
              "--cb-primary-bg-hover": "#26a1ab",
              "--cb-shadow": "none",
            } as React.CSSProperties
          }
        ></div>
      </Portlet>
    </Layout>
  );
}
