import type { TPatient } from "~/reducers/dashboard";

import { deployedRussia, deployedUSA } from "./utils";

export const COURSE_VERSION_V1 = 1;
export const COURSE_VERSION_V2 = 2;
export const COURSE_VERSION_V3 = 3;

export enum Case {
  FULL = 1,
  SHORT = 2,
  SINGLE_ARCH = 3,
  SUPER_SHORT = 4,
  CBCT = 5,
  PLAN_3D = 6,
  CHILDREN = 7,
  RETAINERS = 8,
  PRO = 9,
  TEEN = 10,
  CHILDREN_SHORT = 11,
}

export const CO_TAG_FULL_3_STEPS_CBCT = "FULL_3_STEPS_CBCT";
export const CO_TAG_TEEN_3_STEPS_CBCT = "TEEN_3_STEPS_CBCT";

const SMILE_COURSES = {
  FULL: Case.FULL,
  SHORT: Case.SHORT,
  SINGLE_ARCH: Case.SINGLE_ARCH,
  SUPER_SHORT: Case.SUPER_SHORT,
  CBCT: Case.CBCT,
  PLAN_3D: Case.PLAN_3D,
  CHILDREN: Case.CHILDREN,
  RETAINERS: Case.RETAINERS,
  PRO: Case.PRO,
  TEEN: Case.TEEN,
  CHILDREN_SHORT: Case.CHILDREN_SHORT,
};

const PREDICT_COURSES = {
  FULL: Case.FULL,
  SHORT: Case.SHORT,
  SINGLE_ARCH: Case.SINGLE_ARCH,
  SUPER_SHORT: Case.SUPER_SHORT,
  CBCT: Case.CBCT,
  PLAN_3D: Case.PLAN_3D,
  RETAINERS: Case.RETAINERS,
};

export enum Status {
  UNFILLED_CASE = "S_UNFILLED_CASE",
  RETAKE_IMPRESSIONS = "S_RETAKE_IMPRESSIONS",
  WAITING_FOR_A_PAYMENT = "S_WAITING_FOR_A_PAYMENT",
  _3D_PLAN_APPROVAL = "S_3D_PLAN_APPROVAL",
  PRODUCTION_PRINTING = "S_PRODUCTION_PRINTING",
  PRODUCTION_MOLDING = "S_PRODUCTION_MOLDING",
  PRODUCTION_MARKING = "S_PRODUCTION_MARKING",
  PRODUCTION_ALLOCATING = "S_PRODUCTION_ALLOCATING",
  PRODUCTION_FABRICATING = "S_PRODUCTION_FABRICATING",
  PRODUCTION_QUALITY_CONTROL = "S_PRODUCTION_QUALITY_CONTROL",
  FINAL_STAGE_READY = "S_FINAL_STAGE_READY",
  TREATMENT_FINISHED = "S_TREATMENT_FINISHED",
  CANCEL_TREATMENT = "S_CANCEL_TREATMENT",
  RETAINERS_RETAKE_IMPRESSIONS = "S_RETAINERS_RETAKE_IMPRESSIONS",
  RETAINERS_WAITING_FOR_A_PAYMENT = "S_RETAINERS_WAITING_FOR_A_PAYMENT",
}

// NOTE: Rendering logic in forms calls `is<Some>Course` functions even when no course has been selected.
// That's why we union course type with null.
type CourseId = TPatient["course"]["course_id"] | null;

export function isRetainersCourse(course_id: CourseId) {
  return deployedUSA() ? PREDICT_COURSES["RETAINERS"] == course_id : SMILE_COURSES["RETAINERS"] == course_id;
}

export function isChildrenCourse(course_id: CourseId) {
  return deployedRussia()
    ? SMILE_COURSES["CHILDREN"] == course_id || SMILE_COURSES["CHILDREN_SHORT"] == course_id
    : false;
}

export function isChildrenShortCourse(course_id: CourseId) {
  return SMILE_COURSES["CHILDREN_SHORT"] == course_id;
}

export function isPlanCourse(course_id: CourseId) {
  return deployedRussia() ? SMILE_COURSES["PLAN_3D"] == course_id : PREDICT_COURSES["PLAN_3D"] == course_id;
}

export function isCBCTCourse(course_id: CourseId) {
  return deployedUSA() ? PREDICT_COURSES["CBCT"] == course_id : SMILE_COURSES["CBCT"] == course_id;
}

export function isFullCourse(course_id: CourseId) {
  return deployedUSA() ? PREDICT_COURSES["FULL"] == course_id : SMILE_COURSES["FULL"] == course_id;
}

export function isShortCourse(course_id: CourseId) {
  return deployedUSA() ? PREDICT_COURSES["SHORT"] == course_id : SMILE_COURSES["SHORT"] == course_id;
}

export function isSingleArchCourse(course_id: CourseId) {
  return deployedUSA() ? PREDICT_COURSES["SINGLE_ARCH"] == course_id : SMILE_COURSES["SINGLE_ARCH"] == course_id;
}

export function isSuperShortCourse(course_id: CourseId) {
  return deployedUSA() ? PREDICT_COURSES["SUPER_SHORT"] == course_id : SMILE_COURSES["SUPER_SHORT"] == course_id;
}

export function is3DPROCourse(course_id: CourseId) {
  return deployedUSA() ? false : SMILE_COURSES["PRO"] == course_id;
}

export function isTeenCourse(course_id: CourseId) {
  return deployedUSA() ? false : SMILE_COURSES["TEEN"] == course_id;
}

export function hasDeepCBCT(patient: TPatient) {
  const isDeepCBCTService = patient.deep_cbct == true;
  return isCBCTCourse(patient.course.course_id) || isDeepCBCTService;
}
